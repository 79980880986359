<template>
  <div>
    <PageHeader />
    <b-overlay>
      <b-card>
        <b-row align-v="center" class="mb-3">
          <b-col>
            <LottoTypeSelect
              v-model="selectedLottoType"
              hide-label
            />
          </b-col>
          <b-col>
            <LottoGameSelect
              v-model="selectedLottoGame"
              :lotto-type="selectedLottoType"
              hide-label
            />
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="7">
            <b-row>
              <b-col cols="6">
                <label>ตั้งแต่เวลา</label>
                <b-form-group>
                  <DateTimePicker
                      v-model="startDateTime"
                      :disabled="isFetchingLottoSummary"
                      :max-date="maxStartDateTime"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <label>ถึงเวลา</label>
                <b-form-group>
                  <DateTimePicker
                      v-model="endDateTime"
                      :disabled="isFetchingLottoSummary"
                      :min-date="minEndDateTime"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4">
            <label class="text-white">.</label>
            <b-form-group>
              <b-button-group class="w-100">
                <b-button
                    :disabled="isFetchingLottoSummary"
                    variant="light"
                    @click="onDayChange('today')"
                >
                  วันนี้
                </b-button>
                <b-button
                    :disabled="isFetchingLottoSummary"
                    variant="light"
                    @click="onDayChange('yesterday')"
                >
                  เมื่อวาน
                </b-button>
                <b-button
                    :disabled="isFetchingLottoSummary"
                    variant="light"
                    @click="onDayChange('week')"
                >
                  สัปดาห์นี้
                </b-button>
                <b-button
                    :disabled="isFetchingLottoSummary"
                    variant="light"
                    @click="onDayChange('month')"
                >
                  เดือนนี้
                </b-button>
              </b-button-group>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <label class="text-white">.</label>
            <b-form-group>
              <b-button :disabled="isFetchingLottoSummary" variant="primary" @click.prevent="fetchData">
                <i class="uil uil-search" />
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />

        <template v-if="!isFetching">
          <div v-for="(lotto, key) in lottoSummary.lottoGames" :key="key">
            <h6> {{ lotto.name }} </h6>
            <b-col cols="12" md="12"> </b-col>
            <br />
            <b-table
              :busy="isFetchingLottoSummary"
              :fields="fields"
              :items="lotto.LottoGameRounds"
              :show="isFetchingLottoSummary"
              foot-clone
              responsive
              show-empty
            >
              <template #cell(lottoGameRound_game_date)="data">
                {{ data.value | date }}
              </template>
              <template #cell(count_distinct_user_bet)="data">
                {{ (+data.value).toLocaleString() }}
              </template>
              <template #cell(count_user_bet)="data">
                {{ (+data.value).toLocaleString() }}
              </template>
              <template #cell(lottoGameRound_total_bet_amount)="data">
                {{ (+data.value).toLocaleString() }}
              </template>
              <template #cell(lottoGameRound_total_win_amount)="data">
                {{ (+data.value).toLocaleString() }}
              </template>
              <template #cell(lottoGameRound_affiliate_total_amount)="data">
                {{ (+data.value).toLocaleString() }}
              </template>
              <template #cell(income_amount)="data">
               <span :class="`${+data.value > 0 ? 'text-success' : 'text-danger'}`">
                  {{ (+data.value).toLocaleString() }}
                </span>
              </template>

              <template #foot(lottoGameRound_game_date)="">
                <span>รวม</span>
              </template>
              <template #foot(count_distinct_user_bet)="">
                <span>
                  {{ (+lotto.lottoGameSummaryByLottoGame.countDistinctUserBet).toLocaleString() }}
                </span>
              </template>
              <template #foot(count_user_bet)="">
                <span>
                  {{ (+lotto.lottoGameSummaryByLottoGame.countUserBet).toLocaleString() }}
                </span>
              </template>
              <template #foot(lottoGameRound_total_bet_amount)="">
                <span>
                  {{
                    (+lotto.lottoGameSummaryByLottoGame.lottoGameRoundTotalBetAmount).toLocaleString()
                  }}
                </span>
              </template>
              <template #foot(lottoGameRound_total_win_amount)="">
                <span>
                  {{
                    (+lotto.lottoGameSummaryByLottoGame.lottoGameRoundTotalWinAmount).toLocaleString()
                  }}
                </span>
              </template>
              <template #foot(lottoGameRound_affiliate_total_amount)="">
                <span>
                  {{
                    (+lotto.lottoGameSummaryByLottoGame.lottoGameRoundAffiliateTotalAmount).toLocaleString()
                  }}
                </span>
              </template>
              <template #foot(income_amount)="">
                <span :class="`${+lotto.lottoGameSummaryByLottoGame.incomeAmount > 0 ? 'text-success' : 'text-danger'}`">
                  {{ (+lotto.lottoGameSummaryByLottoGame.incomeAmount).toLocaleString() }}
                </span>
              </template>

              <template #table-busy>
                <div class="text-center">
                  <b-spinner />
                </div>
              </template>
            </b-table>
            <hr />
            <br />
          </div>

          <div>
            <h6> สรุปยอด </h6>
            <b-col cols="12" md="12"> </b-col>

            <b-table
              :busy="isFetchingLottoSummary"
              :fields="fieldsSummary"
              :items="lottoSummary.lottoGames"
              foot-clone
              responsive
              show-empty
            >
              <template #cell(name)="data">
                {{ data.item.lottoGameSummaryByLottoGame.name }}
              </template>
              <template #cell(countUserBet)="data">
                {{ (+data.item.lottoGameSummaryByLottoGame.countUserBet).toLocaleString() }}
              </template>
              <template #cell(countDistinctUserBet)="data">
                {{ (+data.item.lottoGameSummaryByLottoGame.countDistinctUserBet).toLocaleString() }}
              </template>
              <template #cell(lottoGameRoundTotalBetAmount)="data">
                {{
                  (+data.item.lottoGameSummaryByLottoGame.lottoGameRoundTotalBetAmount).toLocaleString()
                }}
              </template>
              <template #cell(lottoGameRoundTotalWinAmount)="data">
                {{
                  (+data.item.lottoGameSummaryByLottoGame.lottoGameRoundTotalWinAmount).toLocaleString()
                }}
              </template>
              <template #cell(lottoGameRoundAffiliateTotalAmount)="data">
                {{
                  (+data.item.lottoGameSummaryByLottoGame.lottoGameRoundAffiliateTotalAmount).toLocaleString()
                }}
              </template>
              <template #cell(incomeAmount)="data">
                <span :class="`${+data.item.lottoGameSummaryByLottoGame.incomeAmount > 0 ? 'text-success' : 'text-danger'}`">
                  {{ (+data.item.lottoGameSummaryByLottoGame.incomeAmount).toLocaleString() }}
                </span>
              </template>
              <template #foot(name)="">
                <span>รวม</span>
              </template>
              <template #foot(countUserBet)="">
                <span>
                  {{ (+lottoSummary.lottoGameSummaryTotal.countUserBet).toLocaleString() }}
                </span>
              </template>
              <template #foot(countDistinctUserBet)="">
                <span>
                  {{ (+lottoSummary.lottoGameSummaryTotal.countDistinctUserBet).toLocaleString() }}
                </span>
              </template>
              <template #foot(lottoGameRoundTotalBetAmount)="">
                <span>
                  {{
                    (+lottoSummary.lottoGameSummaryTotal.lottoGameRoundTotalBetAmount).toLocaleString()
                  }}
                </span>
              </template>
              <template #foot(lottoGameRoundTotalWinAmount)="">
                <span>
                  {{
                    (+lottoSummary.lottoGameSummaryTotal.lottoGameRoundTotalWinAmount).toLocaleString()
                  }}
                </span>
              </template>
              <template #foot(lottoGameRoundAffiliateTotalAmount)="">
                <span>
                  {{
                    (+lottoSummary.lottoGameSummaryTotal.lottoGameRoundAffiliateTotalAmount).toLocaleString()
                  }}
                </span>
              </template>
              <template #foot(incomeAmount)="">
                <span :class="`${+lottoSummary.lottoGameSummaryTotal.incomeAmount > 0 ? 'text-success' : 'text-danger'}`">
                  {{ (+lottoSummary.lottoGameSummaryTotal.incomeAmount).toLocaleString() }}
                </span>
              </template>

              <template #table-busy>
                <div class="text-center">
                  <b-spinner />
                </div>
              </template>
            </b-table>
          </div>
        </template>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import dayjs from 'dayjs'

export default {
  page: {
    title: 'รายการโพย',
  },
  components: {
    LottoTypeSelect: () => import('@components/lotto/lotto-type-select.vue'),
    LottoGameSelect: () => import('@components/lotto/lotto-game-select.vue'),
  },
  data() {
    return {
      isFetching: true,
      fromDate: Date(),
      toDate: Date(),
      selectedLottoType: '',
      selectedLottoGame: '',
      startDateTime: '',
      endDateTime: '',
      fields: [
        {
          key: 'lottoGameRound_game_date',
          label: 'งวดวันที่',
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'count_user_bet',
          label: 'จำนวนโพย',
          class: 'text-center',
          thStyle: {
            minWidth: '80px',
          },
        },
        {
          key: 'count_distinct_user_bet',
          label: 'จำนวนยูส',
          class: 'text-center',
          thStyle: {
            minWidth: '80px',
          },
        },
        {
          key: 'lottoGameRound_total_bet_amount',
          label: 'ยอดแทง',
          class: 'text-center',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'lottoGameRound_total_win_amount',
          label: 'ยอดถูก',
          class: 'text-center text-warning',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'lottoGameRound_affiliate_total_amount',
          label: 'ค่าแนะนำ',
          class: 'text-center text-info',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'income_amount',
          label: 'รายได้',
          class: 'text-center text-success',
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
      fieldsSummary: [
        {
          key: 'name',
          label: 'หวย',
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'countUserBet',
          label: 'จำนวนโพย',
          class: 'text-center',
          thStyle: {
            minWidth: '80px',
          },
        },
        {
          key: 'countDistinctUserBet',
          label: 'จำนวนยูส',
          class: 'text-center',
          thStyle: {
            minWidth: '80px',
          },
        },
        {
          key: 'lottoGameRoundTotalBetAmount',
          label: 'ยอดแทง',
          class: 'text-center',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'lottoGameRoundTotalWinAmount',
          label: 'ยอดถูก',
          class: 'text-center text-warning',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'lottoGameRoundAffiliateTotalAmount',
          label: 'ค่าแนะนำ',
          class: 'text-center text-info',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'incomeAmount',
          label: 'รายได้',
          class: 'text-center text-success',
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingLottoSummary: (state) => state.lotto.isFetchingLottoSummary,
    }),
    ...mapGetters(['isMaster', 'isOwner', 'isAgent', 'lottoSummary']),
    maxStartDateTime() {
      return dayjs(this.endDateTime)
        .subtract(1, 'minute')
        .format('YYYY-MM-DD HH:mm')
    },
    minEndDateTime() {
      return dayjs(this.startDateTime)
        .add(1, 'minute')
        .format('YYYY-MM-DD HH:mm')
    },
  },
  async created() {
    this.onDayChange('today')
    if (this.$route.query.type) {
      this.selectedLottoType = this.$route.query.type
    }
    if (this.$route.query.lottoGame && this.$route.query.lottoGame) {
      this.selectedLottoGame = this.$route.query.lottoGame
      this.isFetching = true
      await this.fetchData()
      this.isFetching = false
    } else {
      this.selectedLottoGame = null
    }
  },
  methods: {
    onDayChange(day) {
      switch (day) {
        case 'yesterday':
          this.startDateTime = dayjs().subtract(1, 'day').startOf('day').format()
          this.endDateTime = dayjs().subtract(1, 'day').endOf('day').format()
          break
        case 'week':
          this.startDateTime = dayjs().startOf('week').format()
          this.endDateTime = dayjs().endOf('week').format()
          break
        case 'month':
          this.startDateTime = dayjs().startOf('month').format()
          this.endDateTime = dayjs().endOf('month').format()
          break
        default:
          this.startDateTime = dayjs().startOf('day').format()
          this.endDateTime = dayjs().endOf('day').format()
      }
    },
    ...mapActions(['fetchLottoSummary']),
    async fetchData() {
      if (this.isFetchingLottoSummary) return
      await this.fetchLottoSummary({
        fromDate: new Date(this.startDateTime),
        toDate: new Date(this.endDateTime),
        gameType: this.selectedLottoType,
        lottoGameId: this.selectedLottoGame,
      })
    },
  },
}
</script>
